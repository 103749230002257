<template>
  <div class="characters-list fixed-draggable-dynamic-table-wrapper-height"
       :class="$route.name !== 'characters' ? 'in-modal' : ''">
    <draggable-dynamic-table ref="charactersListTable"
                             :in-modal="$route.name !== 'characters'"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @row:clicked="showCharacter"
                             @load:more="getCharacters"/>


    <!-- show insert character prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showInsertCharacterPromptStatus"
      @close="showInsertCharacterPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertCharacterBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('characters.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showInsertCharacterPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-character @insert="handleReloadTable"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <button id="insertNewCharacter" v-show="false" @click="showInsertCharacterPromptStatus = true"/>
  </div>
</template>

<script>
import axios from "axios";
import {getCharacters} from "../../../../http/requests/characters"
import CustomIcon from "../../../../components/customIcon/customIcon";
import InsertCharacter from "../insert/insertCharacter";
import CharacterUserList from "../users/list/characterUserList";
import {checkUserPermissions} from "../../../../assets/js/functions";
import historyStates from "@/mixins/historyStates";

  export default {
    name: "charactersList",
    metaInfo() {
      return {
        title: this.$t('characters.list.title')
      }
    },
    components: {CharacterUserList, InsertCharacter, CustomIcon},
    mixins: [historyStates],
    data() {
      return {
        showInsertCharacterPromptStatus: false,
        showCharacterUsersListPromptStatus: false,
        selectedCharacter: {},
        loadingTimer: 0,
        options: {
          id: 'charactersListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'reservableDate',
            i18n: 'characters.table.header.reservableDate',
            width: 'calc(100% / 4)',
            minWidth: 120,
            align: 'center',
          },
          {
            field: 'createdDate',
            i18n: 'characters.table.header.createdDate',
            width: 'calc(100% / 4)',
            minWidth: 120,
            align: 'center',
          },
          {
            field: 'name',
            i18n: 'characters.table.header.name',
            width: 'calc((100% / 4) * 2)',
            minWidth: 150,
          },
          {
            field: 'row',
            i18n: 'characters.table.header.row',
            width: '50px',
            minWidth: 50,
            align: 'center',
            // locked: true,
            // sortable: true,
            footer: {
              /*type: 'auto-counter'*/
            }
          }
        ],
        data: [],
        selectedFilters: [],
        filters: {},
        sorts: ['order[0]=created_at,desc'],
        page: 1,
        endedList: false,
        actions: [
          {
            toolbar: [
              {
                id: 'insertNewCharacter',
                icon: 'PLUS',
                iconPack: 'useral',
                color: 'success',
                permission: 'character.create'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'PRINT',
                iconPack: 'useral'
              },
              {
                id: 'downloadTable',
                // i18n: 'draggableDynamicTable.actions.download',
                icon: 'DOWNLOAD',
                iconPack: 'useral'
              },
              {
                id: {name: 'charactersTrash'},
                type: 'link',
                icon: 'TRASH',
                iconPack: 'useral',
                permission: 'character.delete'
              },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created() {
      if (this.$route.name === 'characters') {
        this.$nextTick(() => {
          this.$store.dispatch('updateNavbarActions', this.actions[0])
        })
      }
      if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
        this.getTableInfoFromHistory()
      } else {
        this.getCharacters()
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getCharacters() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.charactersListTable && this.data.length === 0) {
              this.$refs.charactersListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.charactersListTable && this.data.length > 0) {
              this.$refs.charactersListTable.loadMoreStatus = 'Loading'
            }

            getCharacters(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              const characters = res.data.data
              characters.forEach((character) => {
                const data = {
                  row: this.data.length + 1,
                  id: character.id,
                  name: character.name || '',
                  createdDate: character.created_at || '',
                  usersCount: character.users_count || 0,
                  reservableDate: {
                    value: character.reservable_date || '0',
                    type: 'price',
                    currency: this.$t('characters.labels.day')
                  },
                }
                if (this.$route.name === 'characters') {
                  data.route = {
                    name: 'character',
                    params: {id: character.id}
                  }
                }
                this.data.push(data)
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.charactersListTable) this.$refs.charactersListTable.loadMoreStatus = ''
            })
                .catch((error) => {
                  if (this.$refs.charactersListTable && !axios.isCancel(error)) this.$refs.charactersListTable.loadMoreStatus = 'Danger'
                })
          }
        }, 400)
      },
      showCharacter(row) {
        this.selectedCharacter = row

        if (this.$route.name === 'characters') {
          this.setTableInfoToHistory()
        } else {
          this.showCharacterUsersListPromptStatus = true
        }
      },
      handleReloadTable() {
        this.data = []
        this.showInsertCharacterPromptStatus = false
        this.endedList = false
        this.page = 1
        setTimeout(() => {
          this.getCharacters()
        }, 100)
      },

      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .characters-list {
    &.in-modal {
      height: 100%;
    }
  }
</style>
